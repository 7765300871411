import { useEffect, useState } from "react";

import { useNavigate, Link } from "react-router-dom";

import PollarisApi from "../../services/PollarisApi";

import ToastService from "../../services/ToastService";

export default ({ contractId, onSubmit,onError }) => {

    const apiPollaris = new PollarisApi();

    const toastService = new ToastService();

    const navigate = useNavigate();

    var loaded = false;

    const [customer, setCustomer] = useState(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        const loadCustomer = async () => {

            const auth = JSON.parse(localStorage.getItem("authentication"));
            const customerId = auth.customer.id;
            const response = await apiPollaris.get(`clientes/${customerId}`);
            const loadedCustomer = JSON.parse(response);
            setCustomer(loadedCustomer);
        }

        loadCustomer();

    }, []);

    const submitForm = () => {
        
        onSubmit();
        
        setLoading(true);

        apiPollaris.post(`cobrancas/boleto/${contractId}`, null).then(response => {

            setLoading(false);

            const responseObj = JSON.parse(response);

            navigate(`/pagamento/${responseObj.reference}`);
        }
        ).catch(error => {

            setLoading(false);

            toastService.error("Erro ao processar pagamento", error.message);

            onError();
        }
        );

    }

    return (

        customer ?
            loading ? <div className="text-center text-secondary">
                <h4>Aguarde...</h4>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div> :
                <BoletoPayLoadData submitForm={submitForm} customer={customer} /> :
            <div className="text-center text-secondary">Carregando...</div>
    );
}


const BoletoPayLoadData = ({ customer, submitForm }) => {

    return (<div className="boletoPayLoad">
        <div className="row">
            <div className="col-md-6">
                <div className="mb-3">
                    <b><small>Nome</small></b>
                    <div >{customer.nome} </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-3">
                    <b><small>CPF/CNPJ</small></b>
                    <div >{customer.cnpj} </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-3">
                    <b><small>Email</small></b>
                    <div >{customer.email} </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-3">
                    <b><small>Telefone</small></b>
                    <div >{customer.telefone} </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-3">
                    <b><small>Endereço</small></b>
                    <div >{customer.endereco} </div>
                </div>
            </div>
            <div className="col-md-2 col-6">
                <div className="mb-3">
                    <b><small>Número</small></b>
                    <div >{customer.numero} </div>
                </div>
            </div>
            <div className="col-6 col-md-4">
                <div className="mb-3">
                    <b><small>Bairro</small></b>
                    <div >{customer.bairro} </div>
                </div>
            </div>

            <div className="col-4 col-6">
                <div className="mb-3">
                    <b><small>CEP</small></b>
                    <div >{customer.cep} </div>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="mb-3">
                    <b><small>Cidade</small></b>
                    <div >{customer.cidade} </div>
                </div>
            </div>
            <div className="col-md-2 col-4">
                <div className="mb-3">
                    <b><small>Estado</small></b>
                    <div >{customer.estado} </div>
                </div>
            </div>
            <div className="col-12">
                <div className="mb-3">
                    <Link to="/meus-dados" className="btn btn-outline-dark btn-sm col-12">Editar Dados</Link>
                </div>
            </div>
            <div className="col-12">
                <div className="mb-3">
                    <button onClick={submitForm} className="btn btn-success-pollaris btn-lg col-12"><i className="bi bi-upc"></i> Pagar com Boleto</button>
                </div>
            </div>
        </div>
    </div >
    )
}