import { useEffect, useState } from "react";

import moment from 'moment';

import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";

import ToastService from "../services/ToastService";

import PollarisApi from "../services/PollarisApi";

import Placeholder from "../components/Placeholder";

const PagePagamento = () => {

  let loaded = false;

  const [payment, setPayment] = useState(null);

  const { paymentId } = useParams();

  useEffect(() => {

    if (loaded) return;

    loaded = true;

    const loadPayment = () => {

      const apiPollaris = new PollarisApi();

      apiPollaris
        .get(`cobrancas/codigo/${paymentId}`)
        .then((response) => {
          const loadedPayment = JSON.parse(response);

          document.title = "Área do Cliente | Pagamento " + loadedPayment.id;

          console.log(loadedPayment);

          setPayment(loadedPayment);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    loadPayment();

  }, []);

  return (
    !payment ? <Placeholder /> :
      <div className="container">
        <div>
          <div className="col-md-8 offset-md-2 mb-3">
            <div style={{ fontSize: "2.2em" }}>
              {"Pagamento "}
              <span className="text-secondary" style={{ fontSize: "0.5em" }}>
                #{payment.id}
              </span>
              <div className="text-secondary" style={{ fontSize: "0.4em" }}>
                {moment(payment.datahora).format("DD/MM/YYYY HH:mm:ss")}
              </div>
            </div>
          </div>
          <div className="card col-md-8 offset-md-2 shadow">
            <div className="p-4">
              <span style={{ fontSize: "0.8em" }} className="text-secondary">
                Valor
              </span>
              <h1>
                R${parseFloat(payment.valor).toFixed(2)}
              </h1>
              <span style={{ fontSize: "0.8em" }} className="text-secondary">
                Forma de Pagamento
              </span>
              <h4>
                {payment.pagamento}
              </h4>
              <span style={{ fontSize: "0.8em" }} className="text-secondary">
                Status
              </span>
              <h5>
                {payment.datapago ?
                  <span className="badge bg-success">
                    Autorizado em {moment(payment.datapago).format("DD/MM/YYYY HH:mm:ss")}
                  </span>
                  :
                  <span className="badge bg-warning">
                    Aguardando pagamento
                  </span>
                }
              </h5>
              {
                payment.retorno ?
                  payment.pagamento === "CARTÃO DE CRÉDITO" ?
                    <InformacoesCartaoDeCredito payment={payment} /> :
                    payment.pagamento === "BOLETO" ?
                      <InformacoesBoleto payment={payment} /> :
                      payment.pagamento === "PIX" ?
                        <InformacoesPix payment={payment} /> :
                        null :
                  <div>
                    <span style={{ fontSize: "0.8em" }} className="text-secondary">
                      Informações Adicionais
                    </span>
                    <h4>
                      Lançamento Manual
                    </h4>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
  );
};


const InformacoesCartaoDeCredito = ({ payment }) => {

  const cardData = JSON.parse(payment.retorno);

  return (
    <div>
      <span style={{ fontSize: "0.8em" }} className="text-secondary">
        Número do Cartão
      </span>
      <h4>
        {cardData.Numero}
      </h4>
    </div>
  )
}

const InformacoesBoleto = ({ payment }) => {

  const toastService = new ToastService();

  const boletoData = JSON.parse(payment.retorno);

  const barCodeUrl = `https://bwipjs-api.metafloor.com/?bcid=interleaved2of5&text=${boletoData.CodigoDeBarras}`;

  const copyCode = () => {

    navigator.clipboard.writeText(boletoData.LinhaDigitavel);

    toastService.info("Código copiado!", "");
  }

  return (
    <div>
      <span style={{ fontSize: "0.8em" }} className="text-secondary">
        Linha Digitavel
      </span>
      <div className="mb-4">
        <h5>
          {boletoData.LinhaDigitavel}
        </h5>
      </div>
      <div className="mb-3">
        <img className="img-fluid" src={barCodeUrl} ></img>
      </div>
      <div className="mb-4">
        <div className="row">
          <div className="col-md-6 mb-3">
            <button onClick={() => copyCode()} className="btn btn-sm btn-outline-secondary col-12 col-md-12"><i className="bi bi-clipboard"></i> Copiar Código</button>
          </div>
          <div className="col-md-6">
            <a href={boletoData.UrlImpressao} target="_blank" className="btn btn-sm btn-outline-dark col-12 col-md-12"><i className="bi bi-printer"></i> Imprimir</a>
          </div>
        </div>
      </div>
    </div>
  )
}


const InformacoesPix = ({ payment }) => {

  const toastService = new ToastService();

  const pixData = JSON.parse(payment.retorno);

  const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${pixData.LinhaCopiaCola}&size=180x180`;

  const copyCode = () => {

    navigator.clipboard.writeText(pixData.LinhaCopiaCola);

    toastService.info("Código copiado!", "");
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <span style={{ fontSize: "0.8em" }} className="text-secondary">
            Qr Code
          </span>
          <div>
            <img className="img-fluid" src={qrCodeUrl} ></img>
          </div>
        </div>
        <div className="col-md-6">
          <span style={{ fontSize: "0.8em" }} className="text-secondary">
            Pix Copia e Cola
          </span>
          <div className="mb-4">
            <span style={{ fontSize: "0.8em" }}>
              {pixData.LinhaCopiaCola}
            </span>
          </div>
          <div className="col-md-6 mb-3">
            <button onClick={() => copyCode()} className="btn btn-sm btn-outline-secondary col-12"><i className="bi bi-clipboard"></i> Copiar</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PagePagamento;