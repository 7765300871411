
import { useState } from "react";

import { useNavigate } from "react-router-dom";

import PollarisApi from "../../services/PollarisApi";

import ToastService from "../../services/ToastService";


export default ({ contractId, onSubmit,onError }) => {

    const [loading, setLoading] = useState(false);

    const apiPollaris = new PollarisApi();

    const toastService = new ToastService();

    const navigate = useNavigate();

    const submitForm = () => {

        onSubmit();

        setLoading(true);

        apiPollaris.post(`cobrancas/pix/${contractId}`, null).then(response => {

            setLoading(false);

            const responseObj = JSON.parse(response);

            navigate(`/pagamento/${responseObj.reference}`);
        }
        ).catch(error => {

            setLoading(false);

            toastService.error("Erro ao processar pagamento", error.message);

            onError();
        }
        );

    }

    return (
        loading ? <div className="text-center text-secondary">
            <h4>Aguarde...</h4>
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
            :
            <div className="pixPayLoad">

                <p>
                    Após a confirmação, será exibido um QrCode para que você possa efetuar o pagamento.
                </p>
                <p>
                    <small> A liberação do sistema pode levar até 30 segundos após a confirmação do pagamento</small>
                </p>
                <button onClick={() => submitForm()} className="btn btn-success-pollaris btn-lg col-12"><i className="bi bi-qr-code"></i> Pagar com Pix</button>
            </div>
    );
}