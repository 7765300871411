export default class PagSeguroService {
	encryptCard(cardDetails) {

		if (!window.PagSeguro) {
			console.error("PagSeguro SDK não carregado.");
			return;
		}

		if (window.PagSeguro) {

			const card = window.PagSeguro.encryptCard(cardDetails);

			return card;
		}
	}

	setUp(pagSeguroSession) {
		if (!window.PagSeguro) {
			console.error("PagSeguro SDK não carregado.");
			return;
		}

		if (window.PagSeguro) {

			const request = {
				session: pagSeguroSession,
				env: "PROD",
			}

			console.log(request);

			window.PagSeguro.setUp(request);
		}
	}

	authenticate3DS(request) {
		if (!window.PagSeguro) {
			console.error("PagSeguro SDK não carregado.");
			return;
		}

		if (window.PagSeguro) {

			return window.PagSeguro.authenticate3DS(request);
		}
	}
}