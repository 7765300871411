import { useState } from "react"
import PollarisApi from "../../services/PollarisApi";
import PagSeguroService from "../../services/PagSeguroService";

const CreditCardForm = ({ onSubmit, typeOfCard }) => {

    const api = new PollarisApi();

    //cardCvv = "123",
    //cardExpiry= "02/30",
    //cardName = "joão mariano felipe",
    //cardNumber = "5105 1051 0510 5100 "

    //const [cardNumber, setCardNumber] = useState("4539 6206 5992 2097");
    //const [cardExpiry, setCardExpiry] = useState("12/26");
    //const [cardCvv, setCardCvv] = useState("123");
    //const [cardName, setCardName] = useState("joão mariano felipe");

    const [cardNumber, setCardNumber] = useState("");
    const [cardExpiry, setCardExpiry] = useState("");
    const [cardCvv, setCardCvv] = useState("");
    const [cardName, setCardName] = useState("");

    const [invalidFormClass, setInvalidFormClass] = useState("");

    const handleCardNumber = (value) => {

        const cardNumber = value.replace(/\D/g, "");

        //format the card number into groups of 4
        let cardNumberFormatted = cardNumber?.match(/.{1,4}/g)?.join(" ");

        if (cardNumberFormatted && cardNumberFormatted.length > 19) {
            cardNumberFormatted = cardNumberFormatted.substring(0, 19);
        }

        setCardNumber(cardNumberFormatted);
    }

    const handleCardName = (value) => {
        setCardName(value);
    }

    const handleCardExpire = (value) => {

        const cardExpiry = value.replace(/\D/g, "");

        let cardExpiryFormatted = cardExpiry?.match(/.{1,2}/g)?.join("/");

        if (cardExpiryFormatted && cardExpiryFormatted.length > 5) {

            cardExpiryFormatted = cardExpiryFormatted.substring(0, 5);
        }


        setCardExpiry(cardExpiryFormatted);
    }

    const handleCardCvv = (value) => {

        let formatedCardCvv = value.replace(/\D/g, "");

        if (formatedCardCvv && formatedCardCvv.length > 3) {

            formatedCardCvv = formatedCardCvv.substring(0, 3);

        }

        setCardCvv(formatedCardCvv);
    }


    const sendCreditCardData = (e) => {

        e.preventDefault()
        e.stopPropagation()

        const isValidCardNumber = cardNumber.length === 19;

        const isValidCardExpiry = cardExpiry.length === 5;

        const isValidCardCvv = cardCvv.length === 3;

        const isValidCardName = cardName.length > 0;


        if (isValidCardNumber && isValidCardExpiry && isValidCardCvv && isValidCardName) {

            onSubmit({
                cardNumber: cardNumber,
                cardExpiry: cardExpiry,
                cardCvv: cardCvv,
                cardName: cardName
            });
            
        } else {

            setInvalidFormClass("was-validated");
        }
    }

    return (
        <div className="pixPayLoad">
            <form className={`needs-validation ${invalidFormClass}`} noValidate onSubmit={(e) => sendCreditCardData(e)}>
                <div className="row">
                    <div className="col-12">
                        <div className="form-floating mb-3">
                            <input pattern="[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}" type="phone" className="form-control" value={cardNumber} onChange={(e) => handleCardNumber(e.target.value)} required />
                            <label htmlFor="floatingInput">Número do Cartão</label>
                            <div className="invalid-feedback">
                                O número do cartão com 16 digitos.
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" value={cardName} onChange={(e) => handleCardName(e.target.value)} required />
                            <label htmlFor="floatingInput">Nome</label>
                            <div className="invalid-feedback">
                                Seu nome, como está no cartão.
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-floating mb-3">
                            <input pattern="[0-9]{2}/[0-9]{2}" type="phone" className="form-control" value={cardExpiry} onChange={(e) => handleCardExpire(e.target.value)} required />
                            <label htmlFor="floatingInput">Validade</label>
                            <div className="invalid-feedback">
                                Validade do cartão ex: 06/30.
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-floating mb-3">
                            <input pattern="[0-9]{3}" type="phone" className="form-control" value={cardCvv} onChange={(e) => handleCardCvv(e.target.value)} required />
                            <label htmlFor="floatingInput">CVV</label>
                            <div className="invalid-feedback">
                                Código de segurança ex: 444.
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-floating mb-3">
                            <button className="btn btn-lg btn-success-pollaris col-12"><i className={`bi ${typeOfCard.icon}`}></i> Pagar com {typeOfCard.name} </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CreditCardForm;