import { useState } from "react";

import { useNavigate } from "react-router-dom";

import PollarisApi from "../../services/PollarisApi";

import CreditCardForm from "../CreditCardForm/CreditCardForm";

import ToastService from "../../services/ToastService";
import PagSeguroService from "../../services/PagSeguroService";

export default ({ contractId, typeOfCard, onSubmit, onError }) => {

    const apiPollaris = new PollarisApi();

    const pagSeguroService = new PagSeguroService();

    const navigate = useNavigate();

    const toastService = new ToastService();

    const [loading, setLoading] = useState(false);

    const submitForm = (cardData) => {

        onSubmit();

        setLoading(true);

        apiPollaris.get("cobrancas/credito/public-key").then(async (json) => {

            const response = JSON.parse(json);

            if (response.publicKey) {

                const cardDetails = {
                    publicKey: response.publicKey,
                    holder: cardData.cardName,
                    number: cardData.cardNumber.replace(/\D/g, ""),
                    expMonth: cardData.cardExpiry.split("/")[0],
                    expYear: "20" + cardData.cardExpiry.split("/")[1],
                    securityCode: cardData.cardCvv
                }

                const encriptResult = pagSeguroService.encryptCard(cardDetails);

                cardData.encryptedCard = encriptResult.encryptedCard;

            }

            apiPollaris.post(`cobrancas/${typeOfCard.method}/${contractId}`, cardData).then(response => {

                setLoading(false);

                const responseObj = JSON.parse(response);

                navigate(`/pagamento/${responseObj.reference}`);
            }
            ).catch(error => {

                setLoading(false);

                console.error(error);

                onError();

                toastService.error("Erro ao processar pagamento", error.message);
            });

        });

    }

    return (
        <div className="cardPayLoad">
            {loading ?
                <div className="text-center text-secondary">
                    <h4>Aguarde...</h4>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                : <CreditCardForm onSubmit={submitForm} typeOfCard={typeOfCard} />}
        </div >
    )
}