export default class PollarisApi {
  constructor(login = false) {
    
    this.url = "https://api-v2.pollaris.com.br/";

    if (!login) {

      if (!localStorage.getItem("authentication")) {

        throw new Error("Não autenticado");

      } else {

        const savedToken = JSON.parse(localStorage.getItem("authentication"));

        this.authorization = `${savedToken.token_type} ${savedToken.access_token}`;
      }
    }
  }

  authenticate(url, user, password) {
    const base64Auth = btoa(`${user}:${password}`);

    this.authorization = `Basic ${base64Auth}`;

    return this.request("POST", url);
  }

  get(url, data) {
    return this.request("GET", url, data);
  }

  post(url, data) {
    return this.request("POST", url, data);
  }

  put(url, data) {
    return this.request("PUT", url, data);
  }

  delete(url, data) {
    return this.request("DELETE", url, data);
  }

  request(method, url, data) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(method, this.url + url);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", this.authorization);
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(JSON.parse(xhr.response));
        }
      };
      xhr.onerror = () => {
        reject(JSON.parse(xhr.response));
      };
      xhr.send(JSON.stringify(data));
    });
  }
}
