import { useState } from "react";

import { useNavigate } from "react-router-dom";

import PollarisApi from "../../services/PollarisApi";

import CreditCardForm from "../CreditCardForm/CreditCardForm";

import ToastService from "../../services/ToastService";
import PagSeguroService from "../../services/PagSeguroService";

export default ({ contractId, typeOfCard, onSubmit, onError }) => {

    const apiPollaris = new PollarisApi();

    const pagSeguroService = new PagSeguroService();

    const navigate = useNavigate();

    const toastService = new ToastService();

    const [loading, setLoading] = useState(false);

    // const responseFake = `{"success":true,"body":{"numero":"510510******5100","bandeira":"0","parcelas":"1","transactionId":"34465655","success":true,"message":"Pagamento Autorizado","descricao":"O seu pagamento foi autorizado pela operadora do cartão de crédito."}}`
    // onCompleted(<CreditCardCallBack response={JSON.parse(responseFake)} />);

    const submitForm = (cardData) => {

        onSubmit();

        setLoading(true);

        apiPollaris.get("cobrancas/debito/checkout-session").then(async (json) => {

            let responseSession = JSON.parse(json);


            apiPollaris.get(`cobrancas/CARTÃO DE DÉBITO/${contractId}/payload`).then(async (json) => {

                const responsePayload = JSON.parse(json);

                console.log(responsePayload);

                const address = responsePayload.customer.address;

                let request = {
                    data: {
                        customer: responsePayload.customer,
                        paymentMethod: {
                            type: 'DEBIT_CARD',
                            installments: 1,
                            card: {
                                number: cardData.cardNumber.replace(/\D/g, ""),
                                expMonth: cardData.cardExpiry.split("/")[0],
                                expYear: "20" + cardData.cardExpiry.split("/")[1],
                                holder: {
                                    name: cardData.cardName
                                }
                            }
                        },
                        amount: responsePayload.charges[0].amount,
                        billingAddress: { ...address, postalCode: address.postal_code, regionCode: address.region_code },
                        dataOnly: false

                    }
                }

                pagSeguroService.setUp(responseSession.session);
                pagSeguroService.authenticate3DS(request).then(response3ds => {

                    if (response3ds.status == "AUTH_FLOW_COMPLETED") {

                        cardData.autenticacao = {
                            id: response3ds.id,
                        }

                        apiPollaris.post(`cobrancas/${typeOfCard.method}/${contractId}`, cardData).then(response => {

                            setLoading(false);

                            const responseObj = JSON.parse(response);

                            navigate(`/pagamento/${responseObj.reference}`);
                        }
                        ).catch(error => {

                            setLoading(false);

                            console.error(error);

                            onError();

                            toastService.error("Erro ao processar pagamento", error.message);
                        });
                    } else {
                        onError();
                        toastService.error("Erro ao processar pagamento", response3ds.status);
                        setLoading(false);
                    }


                }).catch((err) => {
                    console.log(err);
                    console.log(err.detail);
                    onError();
                })
            })

        });

    }

    return (
        <div className="cardPayLoad">
            {loading ?
                <div className="text-center text-secondary">
                    <h4>Aguarde...</h4>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                : <CreditCardForm onSubmit={submitForm} typeOfCard={typeOfCard} />}
        </div >
    )
}